<script>
import {stringCompare} from "@utils/stringUtil"
import {sortEnum} from "@utils/objectUtil"

const LOT_MODE_ORDERS = {'initial' : 1,'change' : 2, 'custom' : 3,'excluded' : 4}
export default {
	methods : {
		tableSortSKU(a,b) {
			return stringCompare(a.part.sku,b.part.sku);
		} ,
		tableSortName(a,b) {
			const val = stringCompare(a.part.name,b.part.name);
			return (val == 0) ? this.tableSortSKU(a,b) : val;
		} ,
		tableSortPartType(a,b) {
			const val = stringCompare(a.part.part_type,b.part.part_type);
			return (val == 0) ? this.tableSortSKU(a,b) : val;
		} ,
		tableSortTotal(a,b) {
			const val = a.total-b.total
			return (val == 0) ? this.tableSortSKU(a,b) : val;
		} ,
		tableSortLotMode(a,b) {
			const val = sortEnum(LOT_MODE_ORDERS,a,b,'mode')

			return (val == 0) ? this.tableSortSKU(a,b) : val;
		}
	}
}
</script>
